import React, { useCallback, useReducer, createContext, useState, useEffect } from "react"
import { useLocation } from "@reach/router";
import { Button, Box, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, ModalBody, ModalFooter, VStack, Input, InputGroup, InputLeftAddon, Flex, Spacer, Skeleton, Spinner, NumberInput, Code, Alert, AlertTitle, AlertDescription, AlertIcon } from "@chakra-ui/react";
import { useApiCall, useApiWhenAvailable } from "../util/useApi";
import ProblemErrorDetails from "./ProblemErrorDetails";
import LoadingRedirect from "./LoadingRedirect";
import { skeletonImageResolvers } from "../util/imageResolver";
import ImageResolverCard from "./ImageResolverCard";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useContext } from "react";
import { formatHub, formatImageTag, formatArchitectureTarget } from "../util/formatting";
import EmailVerificationBanner from "./EmailVerificationBanner";
import { useAuth0 } from "@auth0/auth0-react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { ReadOnly } from "./ReadOnly";

/* eslint-disable jsx-a11y/no-autofocus */

/** @typedef {'CLOSED'|'POPULAR'|'REPO'|'IMAGE'|'TAG'|'OS'|'WIN'|'VALIDATE'|'PAYMENT'} PageState */

/**
 * @typedef {Object} State
 * @property {PageState} page
 * @property {PageState} prevPage
 * @property {import("../util/api").NewSubscription} subscription
 * @property {string} repoField
 * @property {string} imageField
 * @property {string} tagFilterField
 * @property {string} winField
 * @property {boolean} validateLookupSuccess
 * @property {boolean} paymentComplete
 * @property {string} paymentValidationError
 * @property {boolean} refreshSubscriptionsToggle
*/

/** @typedef {{ type: 'CANCELED' }} CanceledAction */
/** @typedef {{ type: 'OPENED' }} OpenedAction */
/** @typedef {{ type: 'CLOSED' }} ClosedAction */
/** @typedef {CanceledAction|OpenedAction|ClosedAction} GlobalAction */

/** @typedef {{ type: 'POPULAR_SELECTED'; payload: import("../util/api").ImageResolver; }} PopularSelectedAction */
/** @typedef {{ type: 'POPULAR_CUSTOM' }} PopularCustomAction */
/** @typedef {PopularSelectedAction|PopularCustomAction} PopularAction */

/** @typedef {{ type: 'REPO_BACK' }} RepoBackAction */
/** @typedef {{ type: 'REPO_FIELD_CHANGED', payload: string; }} RepoFieldChangedAction */
/** @typedef {{ type: 'REPO_SELECTED', payload: string; }} RepoSelectedAction */
/** @typedef {RepoBackAction|RepoFieldChangedAction|RepoSelectedAction} RepoAction */

/** @typedef {{ type: 'IMAGE_BACK' }} ImageBackAction */
/** @typedef {{ type: 'IMAGE_FIELD_CHANGED', payload: string; }} ImageFieldChangedAction */
/** @typedef {{ type: 'IMAGE_SELECTED', payload: string; }} ImageSelectedAction */
/** @typedef {ImageBackAction|ImageFieldChangedAction|ImageSelectedAction} ImageAction */

/** @typedef {{ type: 'TAG_BACK' }} TagBackAction */
/** @typedef {{ type: 'TAG_SELECTED', payload: string; }} TagSelectedAction */
/** @typedef {{ type: 'TAG_FIELD_CHANGED', payload: string; }} TagFieldChangedAction */
/** @typedef {TagBackAction|TagFieldChangedAction|TagSelectedAction} TagAction */

/** @typedef {{ type: 'OS_BACK' }} OsBackAction */
/** @typedef {{ type: 'OS_SELECTED', payload: import("../util/api").Target; }} OsSelectedAction */
/** @typedef {OsBackAction|OsSelectedAction} OsAction */

/** @typedef {{ type: 'WIN_BACK' }} WinBackAction */
/** @typedef {{ type: 'WIN_FIELD_CHANGED', payload: string; }} WinFieldChangedAction */
/** @typedef {{ type: 'WIN_SELECTED' }} WinSelectedAction */
/** @typedef {WinBackAction|WinFieldChangedAction|WinSelectedAction} WinAction */

/** @typedef {{ type: 'VALIDATE_BACK' }} ValidateBackAction */
/** @typedef {{ type: 'VALIDATE_LOOKUP_SUCCESS', payload: { repositoryDigest: string; imageHash: string; } }} ValidateLookupSuccessAction */
/** @typedef {{ type: 'VALIDATE_CONFIRMED' }} ValidateConfirmedAction */
/** @typedef {ValidateBackAction|ValidateLookupSuccessAction|ValidateConfirmedAction} ValidateAction */

/** @typedef {{ type: 'PAYMENT_BACK' }} PaymentBackAction */
/** @typedef {{ type: 'PAYMENT_CARD_CHANGED', payload: { complete: boolean; validationError?: string; }}} PaymentCardChangedction */
/** @typedef {PaymentBackAction|PaymentCardChangedction} PaymentAction */

/** @typedef {GlobalAction|PopularAction|RepoAction|ImageAction|TagAction|OsAction|WinAction|ValidateAction|PaymentAction} Action */

const stripePublicKey = process.env.GATSBY_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripePublicKey);

/** @type {State} */
const initialState = {
  page: 'CLOSED',
  prevPage: 'CLOSED',
  subscription: null,
  repoField: '',
  imageField: '',
  tagFilterField: '',
  winField: '',
  validateLookupSuccess: false,
  paymentComplete: false,
  paymentValidationError: null,
  refreshSubscriptionsToggle: false,
};

/**
 * 
 * @param {State} state 
 * @param {Action} action 
 * @returns {State}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'CANCELED': return {
      ...initialState,
      refreshSubscriptionsToggle: state.refreshSubscriptionsToggle,
    };
    case 'CLOSED': return {
      ...initialState,
      refreshSubscriptionsToggle: !state.refreshSubscriptionsToggle,
    };
    case 'OPENED': return {
      ...state,
      page: 'POPULAR',
      subscription: null,
    };
    case 'POPULAR_SELECTED': return {
      ...state,
      subscription: {
        ...state.subscription,
        imageResolver: action.payload
      },
      validateLookupSuccess: false,
      prevPage: state.page,
      page: 'VALIDATE',
    };
    case 'POPULAR_CUSTOM': return {
      ...state,
      page: 'REPO',
    };
    case 'REPO_BACK': return {
      ...state,
      page: 'POPULAR',
    };
    case 'REPO_FIELD_CHANGED': return {
      ...state,
      repoField: action.payload,
    };
    case 'REPO_SELECTED': return {
      ...state,
      subscription: {
        ...state.subscription,
        imageResolver: {
          ...state.subscription?.imageResolver,
          hub: action.payload,
        },
      },
      page: 'IMAGE',
    };
    case 'IMAGE_BACK': return {
      ...state,
      page: 'REPO',
    };
    case 'IMAGE_FIELD_CHANGED': return {
      ...state,
      imageField: action.payload,
    };
    case 'IMAGE_SELECTED': return {
      ...state,
      subscription: {
        ...state.subscription,
        imageResolver: {
          ...state.subscription?.imageResolver,
          image: action.payload,
        },
      },
      page: 'TAG',
    };
    case 'TAG_BACK': return {
      ...state,
      page: 'IMAGE',
    };
    case 'TAG_FIELD_CHANGED': return {
      ...state,
      tagFilterField: action.payload,
    };
    case 'TAG_SELECTED': return {
      ...state,
      subscription: {
        ...state.subscription,
        imageResolver: {
          ...state.subscription?.imageResolver,
          tag: action.payload,
        },
      },
      page: 'OS',
    };
    case 'OS_BACK': return {
      ...state,
      page: 'TAG',
    };
    case 'OS_SELECTED': return {
      ...state,
      subscription: {
        ...state.subscription,
        imageResolver: {
          ...state.subscription?.imageResolver,
          os: action.payload.os,
          architecture: action.payload.architecture,
          variant: action.payload.variant,
        },
      },
      prevPage: state.page,
      validateLookupSuccess: false,
      page: action.payload.os === 'windows' ? 'WIN' : 'VALIDATE',
    };
    case 'WIN_BACK': return {
      ...state,
      page: 'OS',
    };
    case 'WIN_FIELD_CHANGED': return {
      ...state,
      winField: /^\d+$/.test(action.payload) ? action.payload : state.winField,
    };
    case 'WIN_SELECTED': return {
      ...state,
      subscription: {
        ...state.subscription,
        imageResolver: {
          ...state.subscription?.imageResolver,
          maximumWindowsVersion: state.winField === '' ? null : parseInt(state.winField, 10),
        },
      },
      prevPage: state.page,
      validateLookupSuccess: false,
      page: 'VALIDATE',
    };
    case 'VALIDATE_BACK': return {
      ...state,
      page: state.prevPage,
    };
    case 'VALIDATE_LOOKUP_SUCCESS': return {
      ...state,
      subscription: {
        ...state.subscription,
        repositoryDigest: action.payload.repositoryDigest,
        imageHash: action.payload.imageHash,
      },
      validateLookupSuccess: true,
    };
    case 'VALIDATE_CONFIRMED': return {
      ...state,
      page: 'PAYMENT',
    };
    case 'PAYMENT_BACK': return {
      ...state,
      paymentComplete: false,
      page: 'VALIDATE',
    };
    case 'PAYMENT_CARD_CHANGED': return {
      ...state,
      paymentComplete: action.payload.complete,
      paymentValidationError: action.payload.validationError,
    };
    default: throw new Error(`Invalid action type ${/** @type {*} */(action).type}.`);
  }
};

/** @type {React.Context<{ state: State; dispatch: React.Dispatch<Action>; }>} */
const NewSubscriptionModalContext = createContext(undefined);

/** @type {React.FC<{text: string;}>} */
const Recommended = ({text}) => <Flex w="100%" alignItems="center"><Box w="33%"/><Box w="34%">{text}</Box><Box w="33%" fontSize="small">(recommended)</Box></Flex>;

/** @type {React.FC} */
const PopularPage = () => {
  const { state, dispatch } = useContext(NewSubscriptionModalContext);
  const { isLoading, apiResult } = useApiCall(useCallback(api => api.getCommonSubscriptions(), []));

  if (state.page !== 'POPULAR') return null;

  if (!isLoading && apiResult.type !== 'SUCCESS') {
    return <ProblemErrorDetails problemErrorResult={apiResult} />;
  }

  const subscriptions = !isLoading && apiResult.type === 'SUCCESS' ? apiResult.result.subscriptions : skeletonImageResolvers(7);

  return (
    <VStack>
      <Box>Choose one of these popular subscriptions, or create your own!</Box>
      <VStack width="100%">
        {subscriptions.map((subscription, i) =>
            <Skeleton width="100%" isLoaded={!isLoading} key={i}><ImageResolverCard imageResolver={subscription} onClick={() => dispatch({type:'POPULAR_SELECTED', payload: subscription})} /></Skeleton>)}
      </VStack>
    </VStack>
  );
};

/** @type {React.FC} */
const PopularFooter = () => {
  const { state, dispatch } = useContext(NewSubscriptionModalContext);

  if (state.page !== 'POPULAR') return null;

  return (
    <Button rightIcon={<ArrowForwardIcon />} onClick={() => dispatch({type:'POPULAR_CUSTOM'})} autoFocus>Custom subscription</Button>
  );
};

/**
 * @param {State} state 
 * @param {React.Dispatch<Action>} dispatch 
 */
const DispatchRepoNextAction = (state, dispatch) => dispatch({type:'REPO_SELECTED', payload:`https://${state.repoField}`});

/** @type {React.FC} */
const RepoPage = () => {
  const { state, dispatch } = useContext(NewSubscriptionModalContext);

  if (state.page !== 'REPO') return null;

  return (
    <VStack>
      <Box>Start by choosing a Docker repository:</Box>
      <VStack>
        <Button width="100%" autoFocus onClick={() => dispatch({type:'REPO_SELECTED', payload:'https://registry.hub.docker.com'})}>DockerHub</Button>
        <Button width="100%" onClick={() => dispatch({type:'REPO_SELECTED', payload:'https://mcr.microsoft.com'})}>mcr.microsoft.com</Button>
        <Button width="100%" onClick={() => dispatch({type:'REPO_SELECTED', payload:'https://ghcr.io'})}>ghcr.io (GitHub)</Button>
        <InputGroup>
          <InputLeftAddon>https://</InputLeftAddon>
          <Input placeholder="Docker repository" value={state.repoField} onChange={(e) => dispatch({type:'REPO_FIELD_CHANGED', payload:e.target.value})} onKeyDown={(e) => e.key === 'Enter' && DispatchRepoNextAction(state, dispatch)} />
        </InputGroup>
      </VStack>
    </VStack>
  );
};

/** @type {React.FC} */
const RepoFooter = () => {
  const { state, dispatch } = useContext(NewSubscriptionModalContext);

  if (state.page !== 'REPO') return null;

  return (
    <Flex width="100%">
      <Button leftIcon={<ArrowBackIcon />} onClick={() => dispatch({type:'REPO_BACK'})}>Back</Button>
      <Spacer />
      <Button rightIcon={<ArrowForwardIcon />} disabled={!state.repoField} onClick={() => DispatchRepoNextAction(state, dispatch)}>Next</Button>
    </Flex>
  );
};

/**
 * @param {State} state 
 * @param {React.Dispatch<Action>} dispatch 
 */
 const DispatchImageNextAction = (state, dispatch) => dispatch({type:'IMAGE_SELECTED', payload:state.imageField.indexOf('/') === -1 ? `library/${state.imageField}` : state.imageField});

/** @type {React.FC} */
const ImagePage = () => {
  const { state, dispatch } = useContext(NewSubscriptionModalContext);

  if (state.page !== 'IMAGE') return null;

  return (
    <VStack>
      <Box>Please choose the image to watch:</Box>
      <Input placeholder="Docker image" value={state.imageField} onChange={(e) => dispatch({type:'IMAGE_FIELD_CHANGED', payload:e.target.value})} onKeyDown={(e) => e.key === 'Enter' && DispatchImageNextAction(state, dispatch)} />
    </VStack>
  );
};

/** @type {React.FC} */
const ImageFooter = () => {
  const { state, dispatch } = useContext(NewSubscriptionModalContext);

  if (state.page !== 'IMAGE') return null;

  return (
    <Flex width="100%">
      <Button leftIcon={<ArrowBackIcon />} onClick={() => dispatch({type:'IMAGE_BACK'})}>Back</Button>
      <Spacer />
      <Button rightIcon={<ArrowForwardIcon />} disabled={!state.imageField} onClick={() => DispatchImageNextAction(state, dispatch)}>Next</Button>
    </Flex>
  );
};

/** @type {React.FC} */
const TagPageImpl = () => {
  const { state, dispatch } = useContext(NewSubscriptionModalContext);
  const { isLoading, apiResult } = useApiCall(useCallback(api => api.getTags({ hub: state.subscription.imageResolver.hub, image: state.subscription.imageResolver.image }), [state.subscription.imageResolver.hub, state.subscription.imageResolver.image]));

  if (!isLoading && apiResult.type !== 'SUCCESS') {
    return <ProblemErrorDetails problemErrorResult={apiResult} />;
  }

  const allTags = !isLoading && apiResult.type === 'SUCCESS' ? apiResult.result.tags : ["0", "1", "2", "3"];
  const recommendedTag = allTags[0];
  const filteredTags = allTags.filter(x => x.includes(state.tagFilterField));

  return (
    <VStack>
      <Box>Next, choose the tag:</Box>
      <Input placeholder="Filter" value={state.tagFilterField} onChange={(e) => dispatch({type:'TAG_FIELD_CHANGED', payload:e.target.value})} />
      <VStack width="100%">
        {filteredTags.map((tag, i) => <Skeleton width="100%" isLoaded={!isLoading} key={i}><Button width="100%" onClick={() => dispatch({type:'TAG_SELECTED', payload:tag})}>{tag === recommendedTag ? <Recommended text={tag} /> : tag}</Button></Skeleton>)}
      </VStack>
    </VStack>
  );
};

/** @type {React.FC} */
const TagPage = () => {
  const { state } = useContext(NewSubscriptionModalContext);

  if (state.page !== 'TAG') return null;

  return <TagPageImpl/>;
};

/** @type {React.FC} */
const TagFooter = () => {
  const { state, dispatch } = useContext(NewSubscriptionModalContext);

  if (state.page !== 'TAG') return null;

  return (
    <Flex width="100%">
      <Button leftIcon={<ArrowBackIcon />} onClick={() => dispatch({type:'TAG_BACK'})}>Back</Button>
      <Spacer />
    </Flex>
  );
};

/** @type {React.FC} */
const OsPageImpl = () => {
  const { state, dispatch } = useContext(NewSubscriptionModalContext);
  const { isLoading, apiResult } = useApiCall(useCallback(api => api.getTargets({ hub: state.subscription.imageResolver.hub, image: state.subscription.imageResolver.image, tag: state.subscription.imageResolver.tag }), [state.subscription.imageResolver.hub, state.subscription.imageResolver.image, state.subscription.imageResolver.tag]));

  if (!isLoading && apiResult.type !== 'SUCCESS') {
    return <ProblemErrorDetails problemErrorResult={apiResult} />;
  }

  /** @type {import("../util/api").Target[]} */
  const targets = !isLoading && apiResult.type === 'SUCCESS' ? apiResult.result.targets : skeletonImageResolvers(3);
  if (targets.length === 0) {
    targets.push({ os: "linux", architecture: "amd64" });
  }
  const recommendedTarget = targets[0];

  return (
    <VStack>
      <Box>Please select an OS and architecture:</Box>
      <VStack width="100%">
        {targets.map((target, i) => <Skeleton width="100%" isLoaded={!isLoading} key={i}><Button width="100%" onClick={() => dispatch({type:'OS_SELECTED', payload:target})}>{target === recommendedTarget ? <Recommended text={formatArchitectureTarget(target)}/> : formatArchitectureTarget(target)}</Button></Skeleton>)}
      </VStack>
    </VStack>
  );
};

/** @type {React.FC} */
const OsPage = () => {
  const { state } = useContext(NewSubscriptionModalContext);

  if (state.page !== 'OS') return null;

  return <OsPageImpl/>;
};

/** @type {React.FC} */
const OsFooter = () => {
  const { state, dispatch } = useContext(NewSubscriptionModalContext);

  if (state.page !== 'OS') return null;

  return (
    <Flex width="100%">
      <Button leftIcon={<ArrowBackIcon />} onClick={() => dispatch({type:'OS_BACK'})}>Back</Button>
      <Spacer />
    </Flex>
  );
};

/** @type {React.FC} */
const WinPage = () => {
  const { state, dispatch } = useContext(NewSubscriptionModalContext);

  if (state.page !== 'WIN') return null;

  return (
    <VStack>
      <Box>Optional: Specify a maximum Win10 build below</Box>
      <NumberInput placeholder="Windows 10 Build" value={state.winField} onChange={(e) => dispatch({type:'WIN_FIELD_CHANGED', payload:e})} />
    </VStack>
  );
};

/** @type {React.FC} */
const WinFooter = () => {
  const { state, dispatch } = useContext(NewSubscriptionModalContext);

  if (state.page !== 'WIN') return null;

  return (
    <Flex width="100%">
      <Button leftIcon={<ArrowBackIcon />} onClick={() => dispatch({type:'WIN_BACK'})}>Back</Button>
      <Spacer />
      <Button rightIcon={<ArrowForwardIcon />} onClick={() => dispatch({type:'WIN_SELECTED'})}>Next</Button>
    </Flex>
  );
};

/** @type {React.FC} */
const ValidatePageImpl = () => {
  const { state, dispatch } = useContext(NewSubscriptionModalContext);
  const { user } = useAuth0();
  const { isLoading, apiResult } = useApiCall(useCallback(async (api, cancelled) => {
    const result = await api.lookup({ imageResolver: state.subscription.imageResolver });
    if (!cancelled() && result.type === 'SUCCESS') {
      dispatch({type:'VALIDATE_LOOKUP_SUCCESS', payload: { repositoryDigest: result.result.repositoryDigest, imageHash: result.result.imageHash }});
    }
    return result;
  }, [state.subscription.imageResolver, dispatch]));

  const userHasStripeSubscription = (user && user['https://image-watch.com/app_metadata/has_stripe_subscription']) || false;

  return (
    <VStack align="start">
      <Box>Please validate the subscription details below.</Box>
      <ReadOnly label="Hub:" value={formatHub(state.subscription.imageResolver.hub)} />
      <ReadOnly label="Image:" value={formatImageTag(state.subscription.imageResolver.hub, state.subscription.imageResolver.image, state.subscription.imageResolver.tag)} />
      {state.subscription.imageResolver.tag === 'latest' && <Alert status="warning"><AlertIcon/><AlertTitle>Tag is 'latest'.</AlertTitle><AlertDescription>Using the 'latest' tag is not recommended for production environments, as it will opt-in to major version changes.</AlertDescription></Alert>}
      <ReadOnly label="Architecture:" value={formatArchitectureTarget({ os: state.subscription.imageResolver.os, architecture: state.subscription.imageResolver.architecture, variant: state.subscription.imageResolver.variant })} />
      {state.subscription.imageResolver.maximumWindowsVersion && <ReadOnly label="Maximum Windows 10 Version:" value={state.subscription.imageResolver.maximumWindowsVersion.toString()} />}
      <Box>This subscription costs <b>1.00 USD</b> per month. {userHasStripeSubscription && 'This will be added to your current subscription.'}</Box>
      <Box>Your payment information is managed by Stripe and is never sent to our servers.</Box>
      <Box>Automated lookup test: {!isLoading && apiResult.type === 'SUCCESS' && 'Success!'}</Box>
      {isLoading && <Spinner />}
      {!isLoading && apiResult.type !== 'SUCCESS' && <ProblemErrorDetails problemErrorResult={apiResult} />}
      {!isLoading && apiResult.type === 'SUCCESS' && <Box>Once you subscribe, you'll change your references from <Code>{formatImageTag(state.subscription.imageResolver.hub, state.subscription.imageResolver.image, state.subscription.imageResolver.tag)}</Code> to use the repository digest instead. Don't worry about doing this now; we'll send you an email with instructions when you subscribe.</Box>}
      {!isLoading && apiResult.type === 'SUCCESS' && <ReadOnly label="Repository digest:" value={apiResult.result.repositoryDigest} />}
    </VStack>
  );
};

/** @type {React.FC} */
const ValidatePage = () => {
  const { state } = useContext(NewSubscriptionModalContext);

  if (state.page !== 'VALIDATE') return null;

  return <ValidatePageImpl/>;
};

/** @type {React.FC} */
const ValidateFooter = () => {
  const { state, dispatch } = useContext(NewSubscriptionModalContext);
  const { isLoading, user } = useAuth0();

  if (state.page !== 'VALIDATE') return null;

  /** @type {boolean} */
  const nextButtonVisible = !isLoading && user && user.email_verified;
  const userHasStripeSubscription = (user && user['https://image-watch.com/app_metadata/has_stripe_subscription']) || false;
  return (
    <VStack width="100%">
      <EmailVerificationBanner direction="column" />
      <Flex width="100%">
        <Button leftIcon={<ArrowBackIcon />} onClick={() => dispatch({type:'VALIDATE_BACK'})}>Back</Button>
        <Spacer />
        {nextButtonVisible && <Button rightIcon={<ArrowForwardIcon />} onClick={() => dispatch({type:'VALIDATE_CONFIRMED'})} disabled={!state.validateLookupSuccess}>{userHasStripeSubscription ? 'Subscribe' : 'Payment'}</Button>}
      </Flex>
    </VStack>
  );
};

/** @type {React.FC} */
const PaymentPageImpl = () => {
  const { user } = useAuth0();
  const location = /** @type {import("@reach/router").WindowLocation<{ referrer: string }>}} */ (useLocation());
  const { state, dispatch } = useContext(NewSubscriptionModalContext);
  const stripe = useStripe();
  const api = useApiWhenAvailable();
  /** @type {{apiError: import("../util/api").ProblemResult | import("../util/api").ErrorResult; stripeError: import("@stripe/stripe-js").StripeError;}} */
  const initialState = {
    apiError: null,
    stripeError: null,
  };
  const [error, setError] = useState(initialState);
  useEffect(() => {
    if (!stripe) {
      return;
    }
    const action = async () => {
      const successUrl = `${location.origin}/app/subscription/init/{CHECKOUT_SESSION_ID}`;
      const cancelUrl = `${location.origin}/app`;
      const apiResult = await api.paymentPortal({ successUrl, cancelUrl, subscription: state.subscription });
      if (apiResult.type === 'ERROR' || apiResult.type === 'PROBLEM') {
        setError({ apiError: apiResult, stripeError: null });
      } else {
        if (apiResult.result.portalId) {
          const stripeResult = await stripe.redirectToCheckout({ sessionId: apiResult.result.portalId });
          if (stripeResult.error) {
            setError({ apiError: null, stripeError: stripeResult.error });
          }
        } else {
          dispatch({type: 'CLOSED'});
        }
      }
    }
    action();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe]);
  const userHasStripeSubscription = (user && user['https://image-watch.com/app_metadata/has_stripe_subscription']) || false;

  if (error.apiError) {
    return <ProblemErrorDetails problemErrorResult={error.apiError} />;
  }

  if (error.stripeError) {
    return <ProblemErrorDetails problemErrorResult={{type: 'ERROR', error: new Error(error.stripeError.message)}} />;
  }

  return userHasStripeSubscription ?
      <LoadingRedirect>Updating Stripe subscription...</LoadingRedirect> :
      <LoadingRedirect>Redirecting to Stripe payment portal...</LoadingRedirect> ;
};

/** @type {React.FC} */
const PaymentPage = () => {
  const { state } = useContext(NewSubscriptionModalContext);

  if (state.page !== 'PAYMENT') return null;

  return <PaymentPageImpl />;
};

/** @type {React.FC} */
const PaymentFooter = () => {
  const { state, dispatch } = useContext(NewSubscriptionModalContext);

  if (state.page !== 'PAYMENT') return null;

  return (
    <Flex width="100%">
      <Button leftIcon={<ArrowBackIcon />} onClick={() => dispatch({type:'PAYMENT_BACK'})}>Back</Button>
      <Spacer />
      <Button disabled={!state.paymentComplete}>Subscribe</Button>
    </Flex>
  );
};

/** @type {React.FC} */
const NewSubscriptionModal = () => {
  const { state, dispatch } = useContext(NewSubscriptionModalContext);

  return (
    <Elements stripe={stripePromise}>
      <Modal isOpen={state.page !== 'CLOSED'} onClose={() => dispatch({type:'CANCELED'})} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Subscription</ModalHeader>
          <ModalCloseButton tabIndex={-1} />
          <ModalBody>
            <PopularPage />
            <RepoPage />
            <ImagePage />
            <TagPage />
            <OsPage />
            <WinPage />
            <ValidatePage />
            <PaymentPage />
          </ModalBody>
          <ModalFooter>
            <PopularFooter />
            <RepoFooter />
            <ImageFooter />
            <TagFooter />
            <OsFooter />
            <WinFooter />
            <ValidateFooter />
            <PaymentFooter />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Elements>
  );
};

const useNewSubscriptionModal = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const onOpen = useCallback(() => dispatch({type:'OPENED'}), [dispatch]);

  return {
    onOpen,
    contextValue: { state, dispatch },
  };
};

export { useNewSubscriptionModal, NewSubscriptionModal, NewSubscriptionModalContext };
