import React, { useCallback, useReducer, createContext } from "react"
import { Button, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, ModalBody, ModalFooter, VStack, Flex, Spacer } from "@chakra-ui/react";
import { useContext } from "react";
import { formatHub, formatImageTag, formatArchitectureTarget } from "../util/formatting";
import { useApi } from "../util/useApi";
import ProblemErrorDetails from "./ProblemErrorDetails";
import { ReadOnly } from "./ReadOnly";

/* eslint-disable jsx-a11y/no-autofocus */

/** @typedef {'CLOSED'|'DETAILS'} PageState */

/**
 * @typedef {Object} State
 * @property {PageState} page
 * @property {import("../util/api").ExistingSubscription} subscription
 * @property {boolean} refreshSubscriptionsToggle
*/

/** @typedef {{ type: 'CANCELED' }} CanceledAction */
/** @typedef {{ type: 'OPENED', payload: import("../util/api").ExistingSubscription }} OpenedAction */
/** @typedef {{ type: 'CLOSED' }} ClosedAction */
/** @typedef {CanceledAction|OpenedAction|ClosedAction} GlobalAction */

/** @typedef {GlobalAction} Action */

/** @type {State} */
const initialState = {
  page: 'CLOSED',
  refreshSubscriptionsToggle: false,
  subscription: null,
};

/**
 * 
 * @param {State} state 
 * @param {Action} action 
 * @returns {State}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'CANCELED': return {
      ...initialState,
      refreshSubscriptionsToggle: state.refreshSubscriptionsToggle,
    };
    case 'CLOSED': return {
      ...initialState,
      refreshSubscriptionsToggle: !state.refreshSubscriptionsToggle,
    };
    case 'OPENED': return {
      ...state,
      page: 'DETAILS',
      subscription: action.payload,
    };
    default: throw new Error(`Invalid action type ${/** @type {*} */(action).type}.`);
  }
};

/** @type {React.Context<{ state: State; dispatch: React.Dispatch<Action>; }>} */
const SubscriptionDetailsModalContext = createContext(undefined);

/** @type {React.FC} */
const DetailsPage = () => {
  const { state } = useContext(SubscriptionDetailsModalContext);

  if (state.page !== 'DETAILS') return null;

  return (
    <VStack align="start">
      <ReadOnly label="Hub:" value={formatHub(state.subscription.imageResolver.hub)} />
      <ReadOnly label="Image:" value={formatImageTag(state.subscription.imageResolver.hub, state.subscription.imageResolver.image, state.subscription.imageResolver.tag)} />
      <ReadOnly label="Architecture:" value={formatArchitectureTarget({ os: state.subscription.imageResolver.os, architecture: state.subscription.imageResolver.architecture, variant: state.subscription.imageResolver.variant })} />
      {state.subscription.imageResolver.maximumWindowsVersion && <ReadOnly label="Maximum Windows 10 Version:" value={state.subscription.imageResolver.maximumWindowsVersion.toString()} />}
      <ReadOnly label="Repository digest:" value={state.subscription.repositoryDigest} />
    </VStack>
  );
};

/** @type {React.FC} */
const DetailsFooter = () => {
  const { state, dispatch } = useContext(SubscriptionDetailsModalContext);
  const deleteApiCall = useApi(useCallback(api => api.deleteSubscription(state.subscription?.imageResolverHash), [state.subscription?.imageResolverHash]));

  if (state.page !== 'DETAILS') return null;

  if (deleteApiCall.apiResult && deleteApiCall.apiResult.type === 'SUCCESS') {
    dispatch({type: 'CLOSED'});
    return null;
  }

  /** @type {boolean} */
  return (
    <VStack width="100%">
      {deleteApiCall.apiResult && deleteApiCall.apiResult.type !== 'SUCCESS' && <ProblemErrorDetails problemErrorResult={deleteApiCall.apiResult} />}
      <Flex width="100%">
        <Spacer />
        <Button loadingText="Cancelling..." isLoading={deleteApiCall.isBusy} onClick={() => deleteApiCall.call()}>Cancel subscription</Button>
      </Flex>
    </VStack>
  );
};

/** @type {React.FC} */
const SubscriptionDetailsModal = () => {
  const { state, dispatch } = useContext(SubscriptionDetailsModalContext);

  return (
    <Modal isOpen={state.page !== 'CLOSED'} onClose={() => dispatch({type:'CANCELED'})} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Subscription Details</ModalHeader>
        <ModalCloseButton tabIndex={-1} />
        <ModalBody>
          <DetailsPage />
        </ModalBody>
        <ModalFooter>
          <DetailsFooter />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const useSubscriptionDetailsModal = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const onOpen = useCallback((/** @type {import("../util/api").ExistingSubscription} */ subscription) => dispatch({type: 'OPENED', payload: subscription}), [dispatch]);

  return {
    onOpen,
    contextValue: { state, dispatch },
  };
};

export { useSubscriptionDetailsModal, SubscriptionDetailsModal, SubscriptionDetailsModalContext };
