import React from "react"
import { useCallback } from 'react';
import { useApiCall } from "../util/useApi";
import ProblemErrorDetails from "./ProblemErrorDetails";
import { Box, Stack, Skeleton } from "@chakra-ui/react";
import ImageResolverCard from "./ImageResolverCard";
import { skeletonImageResolvers } from "../util/imageResolver";

/** @type {React.FC<{onSubscriptionClick: (subscription: import("../util/api").ExistingSubscription) => void;}>} */
const Subscriptions = ({onSubscriptionClick}) => {
  const { isLoading, apiResult } = useApiCall(useCallback(api => api.getSubscriptions(), []));

  if (isLoading) {
    const skeletonSubscriptions = skeletonImageResolvers(3);
    return (
    <Stack w="100%">
      <Skeleton><ImageResolverCard imageResolver={skeletonSubscriptions[0]} /></Skeleton>
      <Skeleton><ImageResolverCard imageResolver={skeletonSubscriptions[1]} /></Skeleton>
      <Skeleton><ImageResolverCard imageResolver={skeletonSubscriptions[2]} /></Skeleton>
    </Stack>);
  }

  if (apiResult.type !== 'SUCCESS') {
    return <ProblemErrorDetails problemErrorResult={apiResult} />;
  }

  if (!apiResult.result.subscriptions?.length) {
    return <Box>You have no subscriptions yet. Click the "New subscription" button to get started!</Box>;
  }

  return (
  <Stack w="100%">
    {apiResult.result.subscriptions.map(subscription =>
    <ImageResolverCard imageResolver={subscription.imageResolver} onClick={() => onSubscriptionClick(subscription)} key={subscription.imageHash} />)}
  </Stack>);
};

 export default Subscriptions;