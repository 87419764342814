import React from "react"
import { useAuth0 } from "@auth0/auth0-react";
import Subscriptions from "./Subscriptions";
import { Button, Flex, Spacer, VStack } from "@chakra-ui/react";
import { NewSubscriptionModal, NewSubscriptionModalContext, useNewSubscriptionModal } from "./NewSubscriptionModal";
import { SubscriptionDetailsModal, SubscriptionDetailsModalContext, useSubscriptionDetailsModal } from "./SubscriptionDetailsModal";

const Home = () => {
  const { isLoading } = useAuth0();
  const { onOpen: openNew, contextValue: contextNew } = useNewSubscriptionModal();
  const { onOpen: openDetails, contextValue: contextDetails } = useSubscriptionDetailsModal();

  return (
    <NewSubscriptionModalContext.Provider value={contextNew}>
      <SubscriptionDetailsModalContext.Provider value={contextDetails}>
        <VStack w="100%">
          <Flex w="100%"><Button disabled={isLoading} onClick={openNew}>New subscription</Button><Spacer/></Flex>
          <Subscriptions key={`${contextNew.state.refreshSubscriptionsToggle.toString()}^${contextDetails.state.refreshSubscriptionsToggle.toString()}`} onSubscriptionClick={openDetails} />
        </VStack>
        <NewSubscriptionModal />
        <SubscriptionDetailsModal />
      </SubscriptionDetailsModalContext.Provider>
    </NewSubscriptionModalContext.Provider>
  );
};

 export default Home;