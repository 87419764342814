import React from "react";
import { Router } from "@reach/router";
import Profile from "../components/Profile";
import Layout from "../components/Layout";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import BillingPortal from "../components/BillingPortal";
import SubscriptionInit from "../components/SubscriptionInit";
import Home from "../components/Home";
import LoadingRedirect from "../components/LoadingRedirect";
import { Helmet } from "react-helmet";

/**
 * @typedef {React.FC<import("@reach/router").RouteComponentProps>} RoutedComponent
 */

const LoggingInMessage = () => <LoadingRedirect>Logging in...</LoadingRedirect>;

const RoutedProfile = /** @type {RoutedComponent} */ (withAuthenticationRequired(Profile, { onRedirecting: LoggingInMessage }));
const RoutedHome = /** @type {RoutedComponent} */ (withAuthenticationRequired(Home, { onRedirecting: LoggingInMessage }));
const RoutedBillingPortal = /** @type {RoutedComponent} */ (withAuthenticationRequired(BillingPortal, { onRedirecting: LoggingInMessage }));
const RoutedSubscriptionInit = /** @type {RoutedComponent} */ (withAuthenticationRequired(SubscriptionInit, { onRedirecting: LoggingInMessage }));

const App = () => {
  return (
    <Layout>
      <Helmet>
        <title>image-watch</title>
      </Helmet>
      <Router basepath="/app">
        <RoutedSubscriptionInit path="/subscription/init/:stripePaymentSessionId" />
        <RoutedBillingPortal path="/billing" />
        <RoutedProfile path="/profile" />
        <RoutedHome path="/" />
      </Router>
    </Layout>
  );
};

export default App;