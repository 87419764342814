import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner, Image, VStack, Link, Box } from "@chakra-ui/react";
import { ReadOnly } from "./ReadOnly";

const Profile = () => {
  const { isLoading, user } = useAuth0();

  if (isLoading || !user) {
    return <Spinner/>;
  }

  const image = <Image src={user.picture} alt="Profile" />;

  return (
    <VStack>
      {user.picture.includes('gravatar') && <Link href="https://gravatar.com/emails/" target="_blank">{image}</Link>}
      {!user.picture.includes('gravatar') && image}
      <ReadOnly label="Email:" value={user.email} />
      <Box>This is the email that is used for subscription notifications. It is not possible to change this email, but you can create another account with a different email.</Box>
    </VStack>
  );
};

export default Profile;