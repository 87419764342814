import React, { useCallback } from "react";
import { useLocation } from "@reach/router";
import { useApiCall } from "../util/useApi";
import ProblemErrorDetails from "./ProblemErrorDetails";
import LoadingRedirect from "./LoadingRedirect";

const BillingPortal = () => {
  const location = /** @type {import("@reach/router").WindowLocation<{ referrer: string }>}} */ (useLocation());
  const returnUrl = location.state?.referrer ?? `${location.origin}/app`;
  const invokePortal = useCallback(( /** @type {import("../util/api").Api} */ api) => api.billingPortal({ returnUrl }), [returnUrl]);
  const state = useApiCall(invokePortal);

  if (state.isLoading) {
    return <LoadingRedirect>Redirecting to Stripe billing portal...</LoadingRedirect>;
  }

  if (state.apiResult.type !== 'SUCCESS') {
    return <ProblemErrorDetails problemErrorResult={state.apiResult} />;
  }

  window.location.href = state.apiResult.result.portalUrl;

  return <></>;
};

export default BillingPortal;