import { Box, Center, Spinner, VStack } from "@chakra-ui/react";
import React from "react";

/**
 * A large loading message used for page redirects.
 * @type {React.FC}
 */
const LoadingRedirect = ({ children }) => (
  <Center w="100%" my={20}><VStack><Box>{children}</Box><Spinner size="xl" /></VStack></Center>
);

export default LoadingRedirect;