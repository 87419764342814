const dockerHub = 'https://registry.hub.docker.com';

/**
 * Formats the predicates in the image resolver using a os/arch[/variant][>version] format.
 * @param {import("./api").ImageResolver} imageResolver 
 */
export const formatPredicates = (imageResolver) => {
  let result = `${imageResolver.os}/${imageResolver.architecture}`;
  if (imageResolver.variant) {
    result += `/${imageResolver.variant}`;
  }
  if (imageResolver.maximumWindowsVersion) {
    result += `>${imageResolver.maximumWindowsVersion}`;
  }
  return result;
};

/**
 * Formats the target using a os/arch[/variant] format.
 * @param {import("./api").Target} target
 */
export const formatArchitectureTarget = (target) => formatPredicates({hub: '', image: '', tag: '', os: target.os, architecture: target.architecture, variant: target.variant})

/**
 * Returns the hub without the `https://` prefix.
 * @param {string} hub 
 * @returns {string?}
 */
export const formatHub = (hub) => {
  const index = hub.indexOf("://");
  if (index === -1) {
    return hub;
  }
  return hub.substr(index + 3);
};

/**
 * Returns the image name without the `library/` prefix if it is for the common DockerHub.
 * @param {string} hub 
 * @param {string} image 
 * @returns {string}
 */
export const formatImage = (hub, image) => {
  return (hub === dockerHub && image.startsWith('library/')) ? image.substr(8) : image;
};

/**
 * Returns the image with tag in the common `image:tag` pattern.
 * @param {string} hub 
 * @param {string} image 
 * @param {string} tag 
 * @returns {string}
 */
export const formatImageTag = (hub, image, tag) => {
  return `${formatImage(hub, image)}:${tag}`;
};

/**
 * Gets a user-friendly description of the resolver.
 * @param {import("./api").ImageResolver} imageResolver 
 * @returns {{ image: string; hub: string; details: string; }}
 */
export const formatImageResolver = (imageResolver) => {
  const image = formatImageTag(imageResolver.hub, imageResolver.image, imageResolver.tag);
  const hub = imageResolver.hub === dockerHub ? 'DockerHub' : formatHub(imageResolver.hub);
  const details = formatPredicates(imageResolver);
  return { image, hub, details };
};
