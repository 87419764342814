import { Box, Button, VStack } from "@chakra-ui/react";
import React from "react";
import { formatImageResolver } from "../util/formatting";

/** @type {React.FC<{ imageResolver: import("../util/api").ImageResolver; } & import("@chakra-ui/button").ButtonProps>} */
const ImageResolverCard = (props) => {
  const { imageResolver, ...rest } = props;
  const display = formatImageResolver(imageResolver);
  return (
    <Button width="100%" h="auto" py={4} {...rest}>
      <VStack>
        <Box fontWeight="bold">{display.image}</Box>
        <Box fontSize="xs">{display.details} from {display.hub}</Box>
      </VStack>
    </Button>
  );
};

export default ImageResolverCard;