/**
 * Gets a React-friendly key for the resolver.
 * @param {import("./api").ImageResolver} imageResolver 
 */
const imageResolverKey = (imageResolver) =>
    `${imageResolver.hub}^${imageResolver.image}^${imageResolver.tag}^${imageResolver.os}^${imageResolver.architecture}^${imageResolver.variant}^${imageResolver.maximumWindowsVersion}`;

/**
 * @param {number} n 
 * @returns {import("./api").ImageResolver[]}
 */
const skeletonImageResolvers = (n) => {
  /** @type {import("./api").ImageResolver[]} */
  const result = [];
  for (let i = 0; i !== n; ++i) {
    result.push({ hub: "https://registry.hub.docker.com", image: "library/python", tag: n.toString(), os: "linux", architecture: "amd64" });
  }
  return result;
};

export { imageResolverKey, skeletonImageResolvers };