import React, { useCallback } from "react";
import { navigate } from "@reach/router";
import { useApiCall } from "../util/useApi";
import ProblemErrorDetails from "./ProblemErrorDetails";
import LoadingRedirect from "./LoadingRedirect";

/** @type {React.FC<{ stripePaymentSessionId: string; }>} */
const SubscriptionInit = ({ stripePaymentSessionId }) => {
  const state = useApiCall(useCallback(async (api, canceled) => {
    const result = await api.initSubscription(stripePaymentSessionId);
    if (result.type === 'SUCCESS' && !canceled()) {
      navigate('/app');
    }
    return result;
  }, [stripePaymentSessionId]));

  if (!state.isLoading && state.apiResult.type !== 'SUCCESS') {
    return <ProblemErrorDetails problemErrorResult={state.apiResult} />;
  }

  return <LoadingRedirect>Activating subscription...</LoadingRedirect>;
};

export default SubscriptionInit;